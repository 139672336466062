export const links = [
    {
        id: 1,
        name: "Clinics",
        route: "/clinics"
    },
    {
        id: 2,
        name: "physicians",
        route: "/physicians"
    }
];