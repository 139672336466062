import { LOGIN_SUCCESS } from '../../actionTypes';
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const updateUserDetails = (payload) => ({
    type: LOGIN_SUCCESS,
    currentUser: payload,
})
export const userLogin = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/admin/login", payload)
        .then((res) => {
            if (res.status === 200) {
                dispatch(updateUserDetails(res.data.data))
                localStorage.setItem("userDetail", JSON.stringify(res.data.data))
                cookies.set("x-access-token", res.data.data.token, { path: "/" });
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};