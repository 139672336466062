import { links } from "../../siteData/header";
const Sidebar = ({history}) => {

    return (
        <div className="sidebar">
            {
                links.map((link,i) => {
                    return <ul className="list_unstyled" key={i}>
                        <li onClick={() => history.push(link.route)} className={link.route === history.location.pathname ? "active" : ""} ><span>{link.name}</span></li>
                    </ul>
                })
            }

        </div>
    )
};

export default Sidebar