const Footer = () => {
    return (
        <footer>
            <div className="fullWidth">
                <div className="copyright">Copyright © 2021 Haploscope - All Rights Reserved</div>
            </div>  
        </footer>
    )
}

export default Footer