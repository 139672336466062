import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import icone_hide_pass from '../../assets/images/icon_hide_password.svg';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import history from '../../history';
import { userLogin } from '../../redux/auth/action';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/images/header_logo.svg';
toast.configure()

const SignIn = () => {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, setError } = useForm();
    const [handleShow, setHandleShow] = useState(false)

    const loginAdmin = (data) => {
        dispatch(userLogin(data)).then((res) => {
            console.log(res, "res")
            if (res.status === 200) {
                history.push("/clinics");
            } else {
                toast.error(res.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setErrorMessage(res.data.message)
            }
        });
    }

    return (
        <div className="signin">
            <div className="container">
                <div className="row header_logo">
                    <div className="logo"><img src={logo} alt="logo_img" /></div>
                </div>
            </div>
            <section className="sigin_banner">
                <div className="container">
                    <div className="sign_box">
                        <div className="account_system">
                            <h4>Account Management System</h4>
                        </div>
                        <div className="admin_sigin">
                            <h2>Admin Sign In</h2>
                            <form onSubmit={handleSubmit(loginAdmin)}>
                                <div className="form_group">
                                    <div><label>Email</label></div>
                                    <input ref={register({
                                        required: "Email is Required",
                                        pattern: {
                                            value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                            message: "Please enter a valid email",
                                        },
                                    })} type="text" placeholder="Email" className="form_control" name="email" />
                                    {errors.email && (
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {errors.email.message}
                                        </div>
                                    )}
                                </div>
                                <div className="form_group">
                                    <div><label>Password</label></div>
                                    <input ref={register({ required: "Password is Required" })} type={handleShow ? "text" : "password"} placeholder="password" className="form_control pr_4" name="password" />
                                    <span><img src={icone_hide_pass} alt="icone_hide_password" onClick={() => setHandleShow(!handleShow)} /></span>
                                    {errors.password && (
                                        <div className="invalid-feedback pl-4 d-block errorMsg">
                                            {errors.password.message}
                                        </div>
                                    )}
                                </div>
                                <div className="d_flex_center mt_4 mb_4">
                                    <button type="reset" className="btn_default">Clear</button>
                                    <button type="submit" className="btn_primary ml_2">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* {
                modal &&
                <ErrorPop
                    ErrorMessage={ErrorMessage}
                />
            } */}
            <Footer />
        </div>
    )
}

export default SignIn
