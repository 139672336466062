import { GET_CLINIC_LIST } from '../../actionTypes';


const initialState = {
    clinicList:[]
}


export default function loginReducer(state = initialState, action) {
    const { type } = action;
    switch (type) {
        case GET_CLINIC_LIST: {
            return {
                ...state,
                clinicList:action.payload
            }
        }
        default:
            return state;
    }

}