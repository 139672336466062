import Header from './Header';
import Footer from './Footer';
import React, {Suspense, useEffect, useState} from 'react';
import { isAuthenticated } from "../utils";
import Cookies from 'js-cookie';
import Sidebar from "./Sidebar";
import { updateUserDetails } from '../redux/auth/action';
import { useDispatch } from 'react-redux';

const Layout = ({ children, history }) => {
    const dispatch = useDispatch();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [active,setActive] = useState()

    useEffect(() => {
        dispatch(updateUserDetails(JSON.parse(localStorage.getItem('userDetail'))))
    }, [])

    const handleLogout = () => {
        Cookies.remove('x-access-token');
        history.push('/');
        setActive('logout')
    };

    return (
        <div className="layout">
            <Header
                isAuthenticated={isAuthenticated()}
                handleLogout={handleLogout}
                setIsMenuOpen={setIsMenuOpen}
                isMenuOpen={isMenuOpen}
                active={active}
            />
            <section className={isAuthenticated()? "page_wrapper":" inner_wrapper"}>
                {isAuthenticated()&&<Sidebar
                    history={history}
                />}
                <Suspense fallback={""}>
                    {children}
                </Suspense>
            </section>
            {/* <Footer /> */}

        </div>
    )
}

export default Layout;