import { Route, Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { adminRoutes } from '../../routes';
import { isAuthenticated } from "../../utils";

const PrivateRouter = () => {
    return (
        adminRoutes.map(({ path, Component, title }, key) => (
            <Route exact path={path} render={(props) =>
                isAuthenticated() ? <>
                    {title &&
                        <Helmet>
                            <title>{title}</title>
                        </Helmet>
                    }
                    <Component {...props} />
                </> : (<Redirect to={{ pathname: '/', user: isAuthenticated, state: { from: props.location } }} />)
            } key={key} />
        ))
    )
};

export default PrivateRouter
