import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import PrivateRouter from "./hoc/PrivateRouter";
import { isAuthenticated } from './utils';
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import SignIn from './pages/SignIn';
import store from './redux/store';
import history from "./history";
import './assets/sass/app.scss';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <App history={history}>
            <Switch>
              <Route
                exact path="/"
                render={props =>
                  !isAuthenticated() ? <>
                    <Helmet>
                      <title>{"SignIn"}</title>
                    </Helmet>
                    <SignIn {...props} />
                  </> :
                    <Redirect to={{ pathname: '/clinics', user: isAuthenticated, state: { from: props.location } }} />
                }
              />
            </Switch>
            <Switch>
              <PrivateRouter />
            </Switch>
          </App>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
