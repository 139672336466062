import { lazy } from 'react';
const Clinics = lazy(() => import('../pages/Clinics'));
const Physicians = lazy(() => import('../pages/Physicians'));
const AddPhysicians = lazy(() => import('../pages/Clinics/ClinicDetails/PhysicianInfo'));
const ClinicInfo = lazy(() => import('../pages/Clinics/ClinicDetails/ClinicInfo'));
const ClinicDetails = lazy(() => import('../pages/Clinics/ClinicDetails'));
export const adminRoutes = [
    { path: '/clinics', Component: Clinics, title: "Clinics" },
    { path: '/physicians', Component: Physicians, title: "Physicians" },
    { path: '/add-clinic', Component: ClinicInfo, title: "Add Clinic" },
    { path: '/add-physician', Component: AddPhysicians, title: "Add Physician" },
    { path: '/clinic-detail', Component: ClinicDetails, title: "Clinic detail" },
];
