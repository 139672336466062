import React, { useRef, useState, useEffect } from 'react';
import logo from './../../assets/images/header_logo.svg';
import profile_pic from './../../assets/images/profile_pic.png';
import down_arrow from './../../assets/images/Down_arrow.svg';
import notification from './../../assets/images/notification_bell.svg';
import { useSelector } from 'react-redux';
// import cross from './../../assets/images/drop_down_ceross_icon.png';

const Header = ({ isAuthenticated,handleLogout,isMenuOpen,setIsMenuOpen,active }) => {
    const currentUser = useSelector(state => state.loginReducer)

    const ref = useRef()

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target) && e.target.id !== 'profileIcon') { setIsMenuOpen(false) }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => { document.removeEventListener("mousedown", checkIfClickedOutside) }
    }, [isMenuOpen])


    return (
        <>
            <header>
                {
                    isAuthenticated &&
                    <div className="container_fluid">
                        <div className="row">
                            <div className="header px_auto">
                                <div className="logo"><img src={logo} alt="logo_img" /></div>
                                <div className="header_profile">
                                    <div className="profile">
                                        <span className="profile_name">{currentUser.first_name + " " + currentUser.last_name}</span>
                                        <span className="profile_pic" ><img id="profileIcon" onClick={() => setIsMenuOpen(!isMenuOpen)} src={profile_pic} alt="profile_pic" /></span>
                                    </div>
                                    <div className="drop_down">
                                        <div className="cursor_pointer" ><img id="profileIcon" onClick={() => setIsMenuOpen(!isMenuOpen)} src={down_arrow} alt="down_arrow" /></div>
                                        {
                                            isMenuOpen &&
                                            <ul className="list_unstyled drop_down_list" ref={ref}>
                                                <li><a href="#" className={active === 'setting'&&"active"}>Settings <span className="dot_blue d_none"></span></a></li>
                                                <li><a href="#" className={active === 'suport'&&"active"}>Contact Support <span className="dot_blue d_none"></span></a></li>
                                                <li><a href="#" className={active === 'logout' && "active"} onClick={()=>handleLogout()}>Log Out<span className="dot_blue d_none"></span></a></li>
                                            </ul>
                                        }
                                    </div>
                                    <div className="notification">
                                        <img src={notification} alt="notification_icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </header>
        </>
    )
};

export default Header